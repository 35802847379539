img {
    max-width: 100%;
}

.cl-img {
    width: 100px;

}

.preview-img {
    width: 100px;
    height: 100px;
}

.button {
    font-size: smaller;
}

.btn {
    display: inline-block;
    color: #fff;
    border: none;
    padding: 10px 20px;
    margin: 5px;
    border-radius: 5px;
    cursor: pointer;
    text-decoration: none;
    font-size: 15px;
    font-family: inherit;
}

.rounde-image-medium {
    border-radius: "8x";
}